import { Component, h } from "preact"
import api, { ResultI } from "../api"
import Loading from "../components/Loading"
import { setPath } from "../router"

class Pending extends Component {
  async componentDidMount() {
    const services = await api.get<ResultI>("chat/room/member", {
      pending: true,
      page_size: 1,
    })
    console.log({ services })
    if (services.count != 1) {
      setPath("")
    } else {
      const token = services.results[0].token
      setPath(`/chat/${token}`)
    }
    return
  }
  render() {
    return <Loading />
  }
}

export default Pending
