let translations: Record<string, string> = {}

export function setTranslations(trans: Record<string, string>) {
  translations = trans
}

function i18n(
  text: string,
  replacements: Record<string, string | number> = {}
) {
  text = translations[text] || text

  for (const key of Object.keys(replacements)) {
    text = text.replace(`{${key}}`, String(replacements[key]))
  }
  return text
}

export default i18n
