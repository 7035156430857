import { Component, h } from "preact"
import api from "../api"
import dialog from "../components/Dialog"
import TextArea from "../components/TextArea"
import i18n from "../i18n"
import { IdNameI } from "../types"

export interface FileI {
  id: number
  alternateId: string
  service: IdNameI
}

class SelectFile extends Component<{
  user_id: number
  selected_id?: number
  service_id?: number
  onChange: (file: FileI | null) => void
  className?: string
}> {
  state: { files: FileI[] } = {
    files: [],
  }
  async componentDidMount() {
    const files = await api.get<{ results: FileI[] }>("file", {
      user_id: this.props.user_id,
      ...(this.props.service_id
        ? {
            service_id: this.props.service_id,
          }
        : {}),
    })
    this.setState({ files: files.results })
  }

  async handleCreateNewFile() {
    const data = await dialog({
      title: i18n("Create new file"),
      value: {
        description: "",
      },
      children: (props) => (
        <div>
          <TextArea
            label={i18n("Description")}
            value={props.value.description}
            onChange={(ev) => props.setValue({ description: ev.target.value })}
          />
        </div>
      ),
    })

    const data2 = await api.post("file", {
      service_id: this.props.service_id,
      description: data.description,
      user_id: this.props.user_id,
    })
    this.props.onChange(data2)
  }
  render() {
    const { state, props } = this

    return (
      <select
        className={`rounded p-2 ${props.className || ""}`}
        onChange={(ev) => {
          switch (ev.target.value) {
            case "null":
              this.props.onChange(null)
              break
            case "create":
              this.handleCreateNewFile()
              break
            default:
              const id = Number(ev.target.value)
              this.props.onChange(state.files.find((x) => x.id === id))
              break
          }
        }}
      >
        <option>{i18n("Select a file")}</option>
        <option value="null" key="none">
          {i18n("No file")}
        </option>
        {state.files.map((x) => (
          <option key={x.id} value={x.id} selected={props.selected_id === x.id}>
            {x.alternateId} - {x.service.name}
          </option>
        ))}
        <option value="create" key="create">
          {i18n("Create a new one")}
        </option>
      </select>
    )
  }
}

export default SelectFile
