import { Component, h } from "preact"
import api from "../api"
import Loading from "../components/Loading"
import i18n from "../i18n"
import { is_mobile, static_url } from "../utils"
import { MessageLine } from "./chatws"
import { Room } from "./Room"
import SelectFile, { FileI } from "./SelectFile"
import { RoomMemberSimpleI, UserServiceI } from "./types"

interface RoomI {
  id: number
  service: UserServiceI
  roommember: RoomMemberSimpleI
}

interface ManagerRoomProps {
  token: string
  onUpdate?: (props: { is_archived?: boolean }) => void
  onClickBack?: () => void
}

interface ManagerRoomState {
  selected: number[]
  room?: RoomI
  roommember?: {
    id: number
    is_archived: boolean
  },
  token?: string
}

class ManagerRoom extends Component<ManagerRoomProps> {
  state: ManagerRoomState = {
    selected: [],
  }

  async componentDidMount() {
    let token = this.props.token

    // Simple access by id, need the post
    if (token.length < 36){
      const tokenres = await api.post<{
        id: number
        token: string
        is_archived: boolean
      }>("chat/room/member", {
        room_id: token,
      })
      token = tokenres.token
    }

    const room = await api.get(`chat/room/${token}`)
    this.setState({
      room: room,
      roommember: room.roommember,
      token,
    })
    if (is_mobile()) {
      document.querySelector("body").classList.add("fullscreen")
    }
  }

  componentWillUnmount(): void {
    document.querySelector("body").classList.remove("fullscreen")
  }

  handleAssignMessagesToFile(file: FileI | null) {
    const { selected } = this.state
    const file_id = file && file.id
    for (const msg_id of selected) {
      api.patch(`chat/message/${msg_id}`, {
        file_id: file_id,
      })
    }
    // const messages = this.state.messages?.map((x) =>
    //   selected.includes(x.id) ? { ...x, file: file } : x
    // )
    this.setState({
      selected: [],
      // messages
    })
  }
  handleClickMessage(msg: MessageLine) {
    const { selected } = this.state
    if (selected?.includes(msg.id)) {
      this.setState({
        selected: selected.filter((x) => x !== msg.id),
      })
    } else {
      this.setState({ selected: [...selected, msg.id] })
    }
  }
  async handleArchiveRoom(archive: boolean) {
    await api.patch(`chat/room/member/${this.state.roommember?.id}`, {
      is_archived: archive,
    })
    await this.props.onUpdate?.({ is_archived: archive })
    this.setState({
      roommember: { ...this.state.roommember, is_archived: archive },
    })
  }

  render() {
    const { state, props } = this
    const { roommember, room } = this.state

    if (!room) {
      return <Loading />
    }

    const { service } = room
    const { onClickBack } = props

    return (
      <Room
        className="max-h-mainarea"
        token={state.token}
        key={props.token}
        name={
          service && service.user
            ? `${service.user.name} / ${service.service.name}`
            : i18n("Unknown")
        }
        onMessageSeen={() => this.props.onUpdate?.({})}
        onClickMessage={this.handleClickMessage.bind(this)}
        selected={state.selected}
        subheader={
          <div className="flex flex-col md:flex-row my-2 align-center">
            {onClickBack && (
              <div className="flex flex-row mr-6">
                <button className="m-1 mr-2" onClick={onClickBack}>
                  <img src={static_url("/img/btn_arrow_back.png")} />
                </button>
                <div className="flex-1"></div>
                <button
                  className="bg-gray-100 hover:bg-white p-2 shadow rounded self-center text-black"
                  onClick={() =>
                    this.handleArchiveRoom(!roommember.is_archived)
                  }
                >
                  {roommember.is_archived ? i18n("Unarchive") : i18n("Archive")}
                </button>
              </div>
            )}
            <div className="flex flex-col">
              <div className="text-white font-bold">
                <a
                  href={`/users/${service.user.username}/`}
                  target="_blank"
                  rel="noopener"
                >
                  {service.user.name} / {service.service.name} /{" "}
                  {service.contract.name}
                </a>
              </div>
              {state.selected.length > 0 && (
                <div
                  className="flex flex-col md:flex-row"
                  style={{ maxWidth: "calc(100vw - 2em)" }}
                >
                  <div>{i18n("Move selected messages to file:")}</div>
                  <SelectFile
                    className="ml-2 text-black"
                    service_id={service?.service.id}
                    user_id={service?.user?.id}
                    onChange={(file) => this.handleAssignMessagesToFile(file)}
                  />
                </div>
              )}
            </div>
            <span className="flex-1"></span>
            {!onClickBack && (
              <button
                className="bg-gray-100 hover:bg-white p-2 mt-2 shadow rounded self-center text-black"
                onClick={() => this.handleArchiveRoom(!roommember.is_archived)}
              >
                {roommember.is_archived ? i18n("Unarchive") : i18n("Archive")}
              </button>
            )}
          </div>
        }
      />
    )
  }
}

export default ManagerRoom
