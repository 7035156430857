import { Component } from "preact"
import { h } from "preact"
import api, { ResultI } from "../api"
import Loading from "../components/Loading"
import i18n from "../i18n"
import { is_mobile, is_tablet, setStateAsync, static_url } from "../utils"
import { ChatWS } from "./chatws"
import ManagerRoom from "./ManagerRoom"
import { UserI, UserServiceI } from "./types"

interface ChatManagerState {
  users: UserI[]
  services: UserServiceI[]
  user?: UserI
  service?: UserServiceI
  token?: string
  show_archived: boolean
  roommember?: {
    id: number
    is_archived: boolean
  }
  // only for mobile
  show_column: 0 | 1 | 2
  loading_users: boolean
  loading_services: boolean
}

class ChatManager extends Component<{}, ChatManagerState> {
  state: ChatManagerState = {
    show_archived: false,
    users: [],
    services: [],
    show_column: 0,
    loading_users: false,
    loading_services: false,
  }
  chatws: ChatWS = undefined

  async componentDidMount() {
    this.chatws = new ChatWS()
    await this.chatws.connect()
    await this.chatws.subscribe_room_activity(() => {
      this.reloadCounters()
    })

    await this.reloadUsers()
  }

  async reloadUsers() {
    this.setState({
      loading_users: true,
      services: [],
      token: undefined,
      service: undefined,
      roommember: undefined,
      user: undefined,
    })
    const users = await api.get<ResultI<UserI>>("chat/service/user", {
      archived: this.state.show_archived,
    })

    this.setState({ users: users.results, loading_users: false })
  }

  async reloadCounters(props?: { is_archived?: boolean }) {
    if (props?.is_archived !== undefined) {
      this.setState({
        roommember: {
          ...this.state.roommember,
          is_archived: props.is_archived,
        },
      })
      return
    }
    const { user, show_archived } = this.state
    const users = await api.get<ResultI<UserI>>("chat/service/user", {
      archived: show_archived,
    })
    const services = await api.get<ResultI>("chat/service", {
      user_id: user.id,
      archived: show_archived,
    })
    this.setState({ users: users.results, services: services.results })
  }

  async handleClickUser(user: UserI) {
    this.setState({
      user: user,
      loading_services: true,
      service: undefined,
      services: [],
    })
    const services = await api.get<ResultI>("chat/service", {
      user_id: user.id,
      archived: this.state.show_archived,
    })
    this.setState({
      services: services.results,
      token: undefined,
      show_column: 1,
      loading_services: false,
    })
  }

  async handleClickService(service: UserServiceI) {
    this.setState({ service: service })
    const token = await api.post<{
      id: number
      token: string
      is_archived: boolean
    }>("chat/room/member", {
      room_id: service.room.id,
    })
    this.setState({
      token: token.token,
      roommember: token,
      show_column: 2,
    })
  }

  async handleShowArchived(show_archived_str: "true" | "false" | "undefined") {
    const show_archived =
      show_archived_str === "true"
        ? true
        : show_archived_str === "false"
        ? false
        : undefined
    await setStateAsync(this, { show_archived })
    await this.reloadUsers()
  }

  render() {
    const { state } = this

    const mobile = is_mobile()
    let show_col0
    let show_col1
    let show_col2
    let class_col0 = ""
    let class_col1 = ""
    let class_col2 = ""
    const show_message_count = !state.show_archived

    if (is_mobile()){
      console.log("is mobile")
      show_col0 = state.show_column === 0
      show_col1 = state.show_column === 1
      show_col2 = state.show_column === 2

      class_col0 = "flex-1"
      class_col1 = "flex-1"
      class_col2 = "flex-1"
    } else if (is_tablet()){
      console.log("is tablet")
      show_col0 = state.show_column === 0 || state.show_column === 1
      show_col1 = state.show_column === 0 || state.show_column === 1
      show_col2 = state.show_column === 2

      class_col0 = "flex-1"
      class_col1 = "flex-1"
      class_col2 = ""
    } else {
      show_col0 = true
      show_col1 = true
      show_col2 = true

      class_col0 = ""
      class_col1 = ""
      class_col2 = "flex-1"
    }

    // console.log({show_col0, show_col1, show_col2})


    // console.log({ show_message_count, show_archived: state.show_archived })

    return (
      <div className="flex flex-row flex-1 flex-stretch">
        {show_col0 && (
          <div className={`flex flex-col w-1/12 bg-sidemenu-left overflow-auto ${class_col0}`} style={{minWidth: 256}}>
            <select
              onChange={(ev) => this.handleShowArchived(ev.target.value)}
              className="bg-transparent-200 text-black shadow p-2 cursor-pointer hover:bg-transparent mb-2"
            >
              <option value="false">{i18n("Active conversations")}</option>
              <option value="true">{i18n("Archived conversations")}</option>
              <option value="undefined">{i18n("Show All")}</option>
            </select>
            {state.loading_users && <Loading className="p-6 self-center" />}
            {state.users.map((user) => (
              <Item
                key={user.id}
                label={`${user.first_name} ${user.last_name}`}
                value={show_message_count && user.count}
                onClick={() => this.handleClickUser(user)}
                disabled={state.loading_users}
                className={
                  state.user?.id == user.id
                    ? "bg-opacity-50 bg-white"
                    : undefined
                }
              />
            ))}
          </div>
        )}
        {show_col1 && (
          <div className={`flex flex-col w-1/12 bg-sidemenu-center overflow-auto ${class_col1}`} style={{minWidth: 256}}>
            {!show_col0 && (
              <button
                className="m-1"
                onClick={() => this.setState({ show_column: 0 })}
              >
                <img src={static_url("/img/btn_arrow_back.png")} />
              </button>
            )}
            {state.loading_services && <Loading className="p-6 self-center" />}
            {state.services.map((service) => (
              <Item
                key={service.id}
                label={service.service.name}
                value={service.count}
                onClick={() => this.handleClickService(service)}
                disabled={state.loading_services}
                className={
                  state.service?.id == service.id
                    ? "bg-opacity-50 bg-white"
                    : undefined
                }
              />
            ))}
          </div>
        )}
        {show_col2 && (
          <div className={`flex-grow overflow-hidden ${class_col2}`}>
            {state.token ? (
              <ManagerRoom
                key={state.roommember.id}
                token={state.token}
                onUpdate={this.reloadCounters.bind(this)}
                onClickBack={
                  !show_col1
                    ? () => this.setState({ show_column: 1 })
                    : undefined
                }
              />
            ) : (
              <div className="bg-healthmotiv h-full justify-center p-24">
                {i18n("Please select a chat room")}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

const Item = (props: {
  label: string
  value: string | number
  onClick?: () => void
  className?: string
  disabled?: boolean
}) => (
  <button
    className={`text-left p-2  border-b border-gray-400 flex flex-row hover:bg-opacity-60 hover:bg-white items-center ${
      props.className || ""
    } ${props.disabled ? "text-red" : ""}`}
    onClick={!props.disabled ? props.onClick : undefined}
    disabled={props.disabled}
  >
    <span className="flex-grow">{props.label}</span>
    {!!props.value && <CircleText value={props.value} />}
  </button>
)

const CircleText = (props: { value: string | number }) => (
  <span className="text-xs rounded-full bg-blue-500 text-white p-2 max-w-5 min-w-5 max-h-5 min-h-5 flex items-center justify-center">
    {props.value}
  </span>
)

export default ChatManager
