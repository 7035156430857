import { createContext, options } from "preact"
import { sleep } from "."

const DEFAULT_TIMEOUT = 10000

export interface MessageI {
  id: number
  text: string
  options: MessageOptions
}

export interface MessageOptions {
  variant: "info" | "warning" | "error"
  timeout?: number
}

export const User = createContext([] as MessageI[])

let addMessage = (message: MessageI) => 0
let removeMessage = (message_id: number) => 0

async function addMessageWithTimeout(message: MessageI) {
  const id = addMessage(message)
  if (message.options.timeout) {
    await sleep(message.options.timeout)
    removeMessage(id)
  }
}

export default {
  info: (text: string, options: Partial<MessageOptions> = {}) =>
    addMessageWithTimeout({
      id: 0,
      text,
      options: { variant: "info", timeout: DEFAULT_TIMEOUT, ...options },
    }),
  warning: (text: string, options: Partial<MessageOptions> = {}) =>
    addMessageWithTimeout({
      id: 0,
      text,
      options: { variant: "warning", timeout: DEFAULT_TIMEOUT, ...options },
    }),
  error: (text: string, options: Partial<MessageOptions> = {}) =>
    addMessage({ id: 0, text, options: { variant: "error", ...options } }),

  setAddMessage: (am: (message: MessageI) => number) => {
    addMessage = am
  },
  setRemoveMessage: (am: (message_id: number) => number) => {
    removeMessage = am
  },
}
