import {
  Component,
  ComponentChild,
  createRef,
  h,
  JSX,
  RenderableProps,
} from "preact"
import api from "../api"
import i18n from "../i18n"
import Loading from "./Loading"

export interface PageI {
  title: string
  content: string
}

interface CMSContentProps {
  first_of?: string
  not_found?: JSX.Element
}

interface CMSContentState {
  page?: PageI
  not_found?: boolean
}

class CMSContent extends Component<CMSContentProps, CMSContentState> {
  state: CMSContentState = {}
  ref = createRef()

  async componentDidMount() {
    const pages = await api.get("cms/page", {
      first_of: this.props.first_of,
    })

    let page
    let not_found
    if (pages.count !== 1) {
      page = { title: i18n("Not Found"), content: i18n("Content not found") }
      not_found = true
    } else {
      page = pages.results[0]
      not_found = false
    }
    this.setState({ page, not_found })
    this.ref.current.innerHTML = page.content
  }

  render() {
    if (this.state.not_found === true && this.props.not_found) {
      return this.props.not_found
    }
    const { page } = this.state

    return (
      <div className="cms">
        <h3 className="font-bold">{page?.title}</h3>
        <div ref={this.ref} className="mt-10" />
        {!page && <Loading />}
      </div>
    )
  }
}

export default CMSContent
