import { h } from "preact"

interface TextArea {
  label?: string
  value?: string
  onChange?: (ev: Event) => void
}

const TextArea = (props: TextArea) => (
  <div>
    {props.label && <div className="text-sm">{props.label}</div>}
    <textarea
      className="w-full border shadow-1 rounded-lg p-2"
      onChange={props.onChange}
    >
      {props.value}
    </textarea>
  </div>
)

export default TextArea
