import { h, JSX } from "preact"
import Home from "./Home"
import i18n from "./i18n"
import { Room } from "./chat/Room"
import Service from "./chat/Service"
import ChatManager from "./chat/Manager"
import Pending from "./chat/Pending"
import ManagerRoom from "./chat/ManagerRoom"

interface RouteI {
  url: string
  component: any
  title?: string
}
interface RouteRegexI {
  url: RegExp
  component: any
  title?: string
}

const pushstateEvent = new Event("pushstate")

export const routes = preprocess([
  { url: "/", component: Home },
  { url: "/chat/service/(?<service>.*?)", component: Service },
  {
    url: "/chat/manager/",
    component: ChatManager,
    title: i18n("Chat Manager"),
  },
  { url: "/chat/pending/", component: Pending },
  { url: "/chat/(?<token>[^/]*?)", component: Room, title: i18n("Chat") },
  {
    url: "/chat/(?<token>.*?)/file/",
    component: ManagerRoom,
    title: i18n("Chat at File"),
  },
])

function preprocess(routes: RouteI[]): RouteRegexI[] {
  return routes.map((x) => ({
    url: new RegExp(`^${x.url}$`),
    component: x.component,
    title: x.title,
  }))
}

export function getComponent(url: string): JSX.Element {
  if (url.startsWith("/app/")) {
    url = url.slice(4)
  }
  for (const route of routes) {
    const match = route.url.exec(url)
    // console.log({ url, match })
    if (match) {
      if (route.title) {
        document.title = i18n("HealthMotiv - {title}", { title: route.title! })
      } else {
        document.title = i18n("HealthMotiv")
      }
      // console.log("route.component", route.component)
      return <route.component {...(match as any).groups} />
    }
  }
  return (
    <div>
      {i18n("Error finding route")} {url}
    </div>
  )
}

export function setPath(url: string, state = {}) {
  const urlobj = new URL(window.location)
  if (
    url.startsWith("http://") ||
    url.startsWith("https://") ||
    url.startsWith("/")
  ) {
    window.location = url
    return
  }
  urlobj.pathname = `/app/${url}`
  history.pushState(state, "", urlobj)
  window.dispatchEvent(pushstateEvent)
}
export function replacePath(url: string, state = {}) {
  const urlobj = new URL(window.location)
  if (url.startsWith("/")) {
    url = url.slice(1)
  }
  urlobj.pathname = `/app/${url}`
  history.replaceState(state, "", urlobj)
  window.dispatchEvent(pushstateEvent)
}

export default routes
