import { Component, createRef, h } from "preact"
import api from "../api"
import i18n from "../i18n"

interface LoginState {
  username: string
  password: string
  loading: boolean
  error?: string
}

class Login extends Component<{ onLogin: () => void }, LoginState> {
  state: LoginState = {
    username: "",
    password: "",
    loading: false,
  }
  password_ref = createRef()
  button_ref = createRef()

  componentDidMount(): void {
    document.title = "HealthMotiv"
  }

  async handleLogin() {
    this.setState({ loading: true })
    const { state } = this
    try {
      await api.post("user/login", {
        username: state.username,
        password: state.password,
      })
      this.props.onLogin()
      return
    } catch (e) {
      this.setState({ error: i18n("Invalid username or password") })
    }
    this.setState({ loading: false })
  }
  render() {
    const { state } = this

    return (
      <div className="h-mainarea pt-0 flex flex-row items-center justify-center bg-healthmotiv">
        <div className="bg-transparent text-black rounded shadow">
          <div className="p-6">
            <h1 className="text-4xl pb-6 pl-2">{i18n("Sign in")}</h1>
            <div className="flex flex-col">
              <span className="text-sm pl-2">
                {i18n("Username")}{" "}
                <span className="italic text-gray-400">
                  {i18n("(Required)")}
                </span>
              </span>
              <input
                type="email"
                value={state.username}
                onChange={(ev) =>
                  this.setState({ username: ev.target.value, error: undefined })
                }
                onKeyPress={(ev) =>
                  ev.key === "Enter" && this.password_ref.current?.focus()
                }
                className="border rounded-full border-grey bg-blue px-2 py-1 focus:border focus:border-blue"
              />
            </div>
            <div className="flex flex-col my-4">
              <span className="text-sm pl-2">
                {i18n("Password")}{" "}
                <span className="italic text-gray-400">
                  {i18n("(Required)")}
                </span>
              </span>
              <input
                ref={this.password_ref}
                type="password"
                value={state.password}
                onChange={(ev) =>
                  this.setState({ password: ev.target.value, error: undefined })
                }
                onKeyPress={(ev) => {
                  ev.key === "Enter" &&
                    setTimeout(this.handleLogin.bind(this), 100)
                }}
                className="border rounded-full border-grey bg-blue px-2 py-1"
              />
            </div>
            {state.error && <div className="text-red-600">{state.error}</div>}
          </div>
          <button
            ref={this.button_ref}
            className={`${
              state.loading ? "bg-gray-300" : "bg-green"
            } w-full py-4 rounded-b hover:bg-green-300 text-white`}
            onClick={this.handleLogin.bind(this)}
            disabled={state.loading}
          >
            {i18n("Login")}
          </button>
        </div>
      </div>
    )
  }
}

export default Login
