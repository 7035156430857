import { Component, Fragment, h } from "preact"
import i18n from "../i18n"
import { registerPush } from "../serviceworker"

export class NotificationsRequest extends Component {
  state: {
    require_notification_permission?: "granted" | "denied" | "ask"
  } = {}

  componentDidMount() {
    if (!window.Notification) return
    if (Notification.permission === "granted") {
      this.setState({ require_notification_permission: "granted" })
      // If allows notifications, just update just in case gone
      registerPush()
    } else if (Notification.permission === "denied") {
      this.setState({ require_notification_permission: "denied" })
    } else {
      this.setState({ require_notification_permission: "ask" })
    }
  }
  async enableNotifications() {
    if (!window.Notification) return
    await Notification.requestPermission()
    registerPush()
    this.setState({ require_notification_permission: false })
  }

  render() {
    if (this.state.require_notification_permission === "ask")
      return (
        <Fragment>
          <button
            onClick={() => this.enableNotifications()}
            className="w-full bg-blue-500 p-4 shadow hover:bg-blue-300 text-white rounded"
          >
            {i18n("Enable notifications")}
          </button>
        </Fragment>
      )
    return <Fragment></Fragment>
  }
}
