import api from "../api"

export function get_qs(): Record<string, string> {
  const params = new URLSearchParams(location.search)
  const obj: Record<string, string> = {}
  for (const [key, val] of params.entries()) {
    obj[key] = val
  }
  return obj
}

export async function sleep(seconds: number) {
  return new Promise<void>((accept) => {
    setTimeout(() => accept(), seconds)
  })
}

export async function upload_file(
  file: File,
  user_id: number,
  progress?: (progress: number) => void
) {
  const slot = await api.post("/users/upload_file", {
    filename: file.name,
    size: file.size,
    user_id,
  })
  console.log({ slot })

  const chunksize = 1024 * 512
  for (let position = 0; position < file.size; position += chunksize) {
    var blob = file.slice(position, position + chunksize)
    var fd = new FormData()
    fd.append("slot", slot.slot)
    fd.append("user_id", String(user_id))
    fd.append("pos", String(position))
    fd.append("data", blob, "data")
    await api.post("/users/upload_file", fd)
    progress?.((position * 100) / file.size)
  }
  progress?.(100)
  return {
    id: slot.id,
  }
}

// This function is needed because Chrome doesn't accept a base64 encoded string
// as value for applicationServerKey in pushManager.subscribe yet
// https://bugs.chromium.org/p/chromium/issues/detail?id=802280
export function urlBase64ToUint8Array(base64String: string) {
  var padding = "=".repeat((4 - (base64String.length % 4)) % 4)
  var base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

  var rawData = window.atob(base64)
  var outputArray = new Uint8Array(rawData.length)

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export async function setStateAsync(self: {setState: (state: any, func: () => void)}, state: any) {
  return new Promise<void>((accept) => self.setState(state, () => accept()))
}

export function is_mobile(){
  return window.screen.width < 640
}

export function is_tablet(){
  return !is_mobile() && (window.screen.width < 1024)
}

export function static_url (path: str){
  return `/static/debug/${path}`
}

export function range(n: number){
  let ret = []
  for (let i=0;i<n;i++){
    ret.push(i)
  }
  return ret
}

export default {
  sleep,
  get_qs,
  upload_file,
  urlBase64ToUint8Array,
  is_mobile,
  static_url,
}
