export interface UserI {
  username: string
  first_name: string
  last_name: string
  email: string
  id: number
  contract?: {
    name: string
    corporate: {
      name: string
      username: string
    }
  }
  lang: "es" | "en" | "fr"
  brand: {
    shortname: string
    index_url: string
    logo_img: string
    navmenu: { id: string; label: string; url: string }[]
    color1: string
    color2: string
    color3: string
    color4: string
  }
}

let user: UserI | undefined

export function setUser(newuser: typeof user) {
  user = newuser
}

export function getUser() {
  return user
}
