// This is the "Background Sync" service worker

import api from "./api"
import settings from "./settings"
import { urlBase64ToUint8Array } from "./utils"

// Add this below content to your HTML page inside a <script type="module"></script> tag, or add the js file to your page at the very top to register service worker
// If you get an error about not being able to import, double check that you have type="module" on your <script /> tag

/*
 This code uses the pwa-update web component https://github.com/pwa-builder/pwa-update to register your service worker,
 tell the user when there is an update available and let the user know when your PWA is ready to use offline.
*/

let registration: undefined | ServiceWorkerRegistration

async function registerServiceWorker() {
  try {
    registration = await navigator.serviceWorker.register("sw.ts", {
      scope: "/app/",
    })
  } catch (error) {
    console.log("Service worker registration failed:", error)
    return
  }
}

if ("serviceWorker" in navigator) {
  console.log("Registering service worker")
  registerServiceWorker()
}

export async function registerPush() {
  // const push = registration.pushManager.getSubscription()
  const vapid_public_key = urlBase64ToUint8Array(settings.VAPID_PUBLIC_KEY)

  const subscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: vapid_public_key,
  })

  const ret = await api.post("chat/push/registration", subscription)
}
