import { Component, h } from "preact"
import api from "./api"
import { NotificationsRequest } from "./components/NotificationsRequest"
import i18n from "./i18n"
import { setPath } from "./router"
import { is_mobile, range } from "./utils"
import { getUser } from "./utils/user"

interface HomeButtonI {
  url: string
  icon: string
  name: string
  ficon?: string
  order: number
  count?: number
}

interface HomeState {
  entries: HomeButtonI[]
  reload_timeout?: number
}

class Home extends Component {
  state: HomeState = {
    entries: [],
  }
  async componentDidMount() {
    await this.reloadMenu()
    const reload_timeout = setInterval(() => this.reloadMenu(), 3 * 60 * 1000) // every 3 minutos
    this.setState({ reload_timeout })
  }

  componentWillUnmount(): void {
    if (this.state.reload_timeout) {
      clearInterval(this.state.reload_timeout)
    }
  }

  async reloadMenu() {
    const entries = await api.get("user/menu", {})
    this.setState({ entries: entries.results })
  }

  render() {
    let fontSize: number,
      iconSize: number,
      buttonSize: number,
      textFontSize: number,
      buttonMargin: number,
      mainPadding: number

    if (is_mobile()) {
      fontSize = 40
      iconSize = 48
      buttonSize = 70
      textFontSize = 12
      buttonMargin = 16
    } else {
      fontSize = 80
      iconSize = 100
      buttonSize = 132
      textFontSize = 19
      buttonMargin = 24
    }

    {
      const width = window.outerWidth
      const buttonFullSize = buttonSize + 2 * buttonMargin
      const nbuttons = Math.floor(width / buttonFullSize)
      mainPadding = (width - nbuttons * buttonFullSize) / 2
    }

    return (
      <div className="h-full bg-healthmotiv h-main-full flex flex-col items-center overflow-scroll">
        <div className="px-12px self-center">
          <NotificationsRequest />
        </div>
        <div>
          <div className="text-2xl text-center pt-12 text-gray-700">
            {i18n("Hello, {name}", { name: getUser().first_name })}
          </div>
          <div
            style={{
              maxWidth: 768,
              paddingRight: mainPadding,
              paddingLeft: mainPadding,
            }}
            className="bg-blue flex flex-wrap items-start "
          >
            {this.state.entries.map((button) => (
              <div className="flex-1 flex items-center justify-center">
                <button
                  onClick={() => setPath(button.url)}
                  className="relative"
                  style={{ margin: buttonMargin }}
                >
                  {!!button.count && (
                    <span className="bg-blue-400 text-white px-2 border-2 border-white -top-2 -right-2 absolute rounded-xl">
                      {button.count}
                    </span>
                  )}
                  <div
                    style={{
                      backgroundImage: button.icon,
                      width: buttonSize,
                      height: buttonSize,
                      minWidth: buttonSize,
                      minHeight: buttonSize,
                    }}
                    className="border-1 bg-white rounded-3xl bg-grey-gradient bg-grey-gradient:hover border border-gray-400 flex flex-row items-center justify-center  border border-grey shadow-xl"
                  >
                    {button.ficon ? (
                      <i
                        className="icon"
                        style={{ fontSize, color: "#0d6d40" }}
                      >
                        {String.fromCharCode(Number(`0x${button.ficon}`))}
                      </i>
                    ) : (
                      <img
                        src={button.icon}
                        style={{ maxWidth: iconSize, maxHeight: iconSize }}
                        alt={button.name}
                      />
                    )}
                  </div>
                  <div
                    className="font-bold leading-5"
                    style={{ fontSize: textFontSize, maxWidth: buttonSize }}
                  >
                    {button.name}
                  </div>
                </button>
              </div>
            ))}
            {this.state.entries.length % 3 != 0 &&
              range(3 - (this.state.entries.length % 3)).map((x) => (
                <div className="flex-1"></div>
              ))}
          </div>
        </div>
      </div>
    )
  }
}

export default Home
