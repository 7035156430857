import { Component, createRef, h, JSX } from "preact"
import { User } from ".."
import api from "../api"
import CMSContent from "../components/CMSContent"
import Markdown from "../components/Markdown"
import { NotificationsRequest } from "../components/NotificationsRequest"
import i18n from "../i18n"
import settings from "../settings"
import { IdCodeNameI, IdNameI } from "../types"
import utils, { get_qs, upload_file } from "../utils"
import message from "../utils/message"
import { getUser } from "../utils/user"
import { ChatWS, MessageLine } from "./chatws"

const TIMEOUT_SEEN = 1 * 1000

interface RoomState {
  name: string
  text: string
  messages?: MessageLine[]
  error?: string
  connected: boolean
  user?: IdNameI
  service?: IdCodeNameI & { end?: string }
  upload_progress?: string
  attachment_ids: IdNameI[]
}

interface RoomProps {
  name?: string
  token: string
  style?: JSX.CSSProperties
  onMessageSeen?: () => void
  onClickMessage?(msg: MessageLine): void
  subheader?: JSX.Element
  selected?: number[]
  className?: string
}

export class Room extends Component<RoomProps, RoomState> {
  state: RoomState = {
    name: "",
    text: "",
    connected: false,
    attachment_ids: [],
  }
  chatws?: ChatWS
  input_ref = createRef()
  messages_ref = createRef()
  attachment_ref = createRef()
  timerSeen: any = undefined

  async componentDidMount() {
    // let messages: MessageLine[]| | undefined = undefined
    let token = this.props.token
    this.chatws = new ChatWS()

    this.chatws.onConnect = async () => {
      try {
        const res = await this.chatws.subscribe(token)
        this.setState(
          {
            messages: res.messages,
            name: res.name,
            user: res.user,
            service: res.service,
          },
          () => this.scrollToBottom()
        )
        if (res.messages?.length >= 1) {
          this.setupTimerForSeenMessage(
            res.messages[res.messages.length - 1].id
          )
        }
        this.setState({ connected: true })
      } catch (e) {
        this.setState({ error: e.toString() })
        return
      }
      await utils.sleep(0.2)
      this.input_ref.current && this.input_ref.current.focus()
    }
    this.chatws.onDisconnect = () => {
      this.setState({ connected: false })
    }

    this.chatws.onMessage((message: MessageLine) => {
      this.setState({ messages: [...this.state.messages, message] }, () => {
        this.messages_ref.current.scrollTo({ top: 100000, behaviour: "smooth" })
      })
      this.setupTimerForSeenMessage(message.id)
    })

    this.chatws.addListener("message-update", (message) =>
      this.updateMessage(message)
    )

    this.chatws.connect()

    console.log("Ready")
  }

  updateMessage(message: MessageLine) {
    console.log("replace message", { message })
    const messages = this.state.messages.map((x) =>
      x.id === message.id ? message : x
    )
    this.setState({ messages })
  }

  setupTimerForSeenMessage(message_id: number) {
    if (this.timerSeen) clearTimeout(this.timerSeen)
    this.timerSeen = setTimeout(() => {
      this.chatws.seen(message_id)
      this.props.onMessageSeen?.()
    }, TIMEOUT_SEEN)
  }

  async componentWillUnmount() {
    if (this.timerSeen) clearTimeout(this.timerSeen)
    this.chatws.disconnect()
    this.chatws = undefined
  }

  handleSend(text: string) {
    if (!this.state.connected) {
      return
    }
    if (!this.chatws || !text) {
      return
    }
    if (this.state.attachment_ids) {
      this.chatws.sendAttachment(text, this.state.attachment_ids)
      this.setState({ attachment_ids: [] })
    } else {
      this.chatws.sendMessage(text)
    }

    this.scrollToBottom()
    this.setState({ text: "" })
    this.input_ref.current.focus()
  }

  scrollToBottom() {
    setTimeout(() => {
      this.messages_ref.current.scrollTo({
        top: 100000,
        behaviour: "smooth",
      })
    }, 300)
  }

  async handleSendFiles() {
    if (!this.state.connected) {
      return
    }
    const files = this.attachment_ref.current.files

    this.setState({ upload_progress: 0 })

    for (const file of files) {
      const att_id = await upload_file(file, this.state.user.id, (progress) => {
        const upload_progress = (progress / files.length).toFixed(2)
        this.setState({ upload_progress })
      })
      this.setState({
        attachment_ids: [
          ...this.state.attachment_ids,
          { id: att_id.id, name: file.name },
        ],
      })
      // this.chatws.sendAttachment(file.name, att_id.id)
    }

    this.setState({ upload_progress: undefined })
  }

  async deleteAttachment(attachment: IdNameI) {
    try {
      await api.post(
        `/users/${this.state.user.name}/ajax/attachment_remove/${attachment.id}`
      )
    } catch (e) {
      console.error(e)
      message.error(i18n("Could not remove attachment from server."))
      return
    }
    this.setState({
      attachment_ids: this.state.attachment_ids.filter(
        (y) => y.id !== attachment.id
      ),
    })
  }

  render() {
    const { state, props } = this
    const { selected } = props

    let style = props.style
    console.log(get_qs()["embeded"])
    if ("embeded" in get_qs()) {
      style = { ...style, maxHeight: "calc(100vh - 20px)" }
    }
    const is_expired =
      state.service?.end && new Date().toISOString() > state.service.end

    return (
      <User.Consumer>
        {(user) => (
          <div
            className={`w-full h-full bg-color2 flex-1 p-4 flex flex-col overflow-hidden ${props.className}`}
            style={style}
          >
            <NotificationsRequest />
            <div className="pb-4 flex flex-row">
              <div className="flex-1 text-white keyboard:hidden">
                {props.subheader || state.service?.name}
              </div>
              {!state.connected && (
                <div className="flex flex-row items-center">
                  {i18n("Connecting...")}{" "}
                  <img
                    className="pl-4"
                    alt=""
                    src={require("../../static/img/loading.gif")}
                  />
                </div>
              )}
            </div>
            {state.error && <div className="error">{state.error}</div>}

            <div
              className="m-12px bg-white rounded bg-healthmotiv border-0 border-grey-100 shadow-inner flex flex-col flex-1 overflow-auto"
              style="max-height: 100%"
              ref={this.messages_ref}
            >
              {state.service && (
                <CMSContent
                  first_of={`filelist_description_${state.service.code}_chat|filelist_description_${state.service.code}`}
                  not_found={<span></span>}
                />
              )}
              {(state.messages || []).map((msg: MessageLine) => (
                <ChatMessage
                  key={msg.id}
                  message={msg}
                  is_mine={msg.author === user.username}
                  selected={selected?.includes(msg.id)}
                  onClick={
                    this.props.onClickMessage &&
                    (() => this.props.onClickMessage?.(msg))
                  }
                />
              ))}
              <div className="flex-1"></div>
              {is_expired && (
                <div className="bg-yellow-500 text-black border justify-center p-12 rounded-lg shadow m-6">
                  {i18n(
                    "This contract is expired. Can read messages but not send new ones."
                  )}
                </div>
              )}
              {!is_working_hours() && (
                <div className="font-bold text-black m-6 bg-white border justify-center p-12 shadow">
                  {i18n(
                    "This messaging service is available monday to friday, from 9am to 6pm, spanish mainland time.  We will answer as soon as possible."
                  )}
                  <br />
                  {i18n(
                    "For any urgent inquiry, please call our 24h medical service. Thanks!"
                  )}
                </div>
              )}
            </div>
            {state.upload_progress !== undefined && (
              <div className="text-right">
                {i18n("Uploading file {progress}%", {
                  progress: state.upload_progress,
                })}
              </div>
            )}
            {state.attachment_ids.length > 0 && (
              <div className="bg-white rounded mt-4 p-4 flex flex-row">
                <div>
                  {state.attachment_ids.map((x) => (
                    <span
                      className="inline-block bg-gray-100 min-w-24px p-4 rounded mr-4 mt-4"
                      key={x.id}
                    >
                      <div className="flex flex-row">
                        <img
                          src={require("../../static/img/icons/attach_file_icon.png")}
                        />
                        <span className="flex-1"></span>
                        <button
                          className="shadow hover:bg-white w-6 h-6 flex items-center justify-center rounded-full ml-6 -mr-3 -mt-3"
                          onClick={() => this.deleteAttachment(x)}
                        >
                          &#215;
                        </button>
                      </div>
                      {x.name}
                    </span>
                  ))}
                </div>
                <div className="self-end">
                  {i18n("{count} attachment(s).", {
                    count: state.attachment_ids.length,
                  })}
                  <br />
                  {i18n("Write a message and send.")}
                </div>
              </div>
            )}

            <div className="mt-2 flex flex-row">
              <input
                className="flex-1 rounded p-1 px-2 shadow-inner"
                style={{ maxWidth: "calc(100vw - 9em)" }}
                disabled={!this.state.connected || is_expired}
                value={
                  this.state.connected
                    ? this.state.text
                    : i18n(
                        "Disconnected from server. We are trying to connect. Please wait."
                      )
                }
                ref={this.input_ref}
                onChange={(ev) => this.setState({ text: ev.target.value })}
                onKeyDown={(ev) => {
                  if (ev.code == "Enter") this.handleSend(ev.target.value)
                }}
              />
              <div className="px-2"></div>
              <button
                className="bg-gray-100 rounded shadow p-2 px-4 text-xs  hover:bg-white"
                disabled={!this.state.connected}
                onClick={() => this.handleSend(this.state.text)}
              >
                {i18n("Send")}
              </button>
              <input
                ref={this.attachment_ref}
                type="file"
                className="hidden"
                multiple
                onChange={this.handleSendFiles.bind(this)}
              />
              <button
                disabled={!this.state.connected}
                onClick={() => this.attachment_ref.current.click()}
                style={{ minWidth: 32 }}
                className="px-3"
              >
                <img
                  src={require("../../static/img/icons/attach_file_icon.png")}
                />
              </button>
            </div>
          </div>
        )}
      </User.Consumer>
    )
  }
}

const ChatMessage = ({
  message,
  is_mine,
  selected,
  onClick,
}: {
  message: MessageLine
  is_mine: boolean
  selected?: boolean
  onClick?: () => void
}) => {
  const file = message.file

  const is_revisor = !getUser().contract

  return (
    <button
      className={`m-4 p-4 min-w-1/3 rounded-lg text-left text-white shadow-dark flex flex-col border ${
        is_mine
          ? "bg-gray-100 text-gray-700 self-end"
          : "bg-color2 text-white self-start"
      } ${
        selected
          ? "border-color4"
          : is_mine
          ? "border-gray-100"
          : "border-color3"
      } ${onClick ? "hover:border-blue-300" : "cursor-default"}`}
      onClick={onClick}
    >
      {message.text && <Markdown text={message.text} className="text" />}
      <div className="text-xs text-right">
        <div>
          <div className="">{message.author}</div>
          {file && (
            <a
              className="text-blue-500 hover:text-blue-400"
              href={`/file/${file.alternateId}/`}
              target="_blank"
              onClick={(ev) => ev.stopPropagation()}
            >
              {file.alternateId} - {file.service.name}
            </a>
          )}
          <Timestamp when={message.timestamp} fulldate={is_revisor} />
        </div>
        {message.attachments && (
          <div className="flex flex-row flex-wrap my-3">
            {message.attachments.map((attachment) => (
              <a
                href={attachment.url}
                target="_blank"
                className={`border rounded flex-inline flex-row p-2 mr-2  ${
                  is_mine
                    ? "bg-gray-200 hover:bg-white"
                    : "bg-green-400 hover:bg-green-100"
                }`}
                onClick={(ev) => ev.stopPropagation()}
              >
                <img
                  src={require("../../static/img/icons/attach_file_icon.png")}
                />
                <div>{attachment.name}</div>
                <div>{attachment.size} bytes</div>
              </a>
            ))}
          </div>
        )}
      </div>
    </button>
  )
}

const Timestamp = ({ when, fulldate }: { when: string; fulldate: boolean }) => {
  const datestr = when.slice(0, 16).replace("T", " ")

  if (fulldate) {
    return (
      <div title={datestr} className="timestampp">
        {datestr}
      </div>
    )
  }

  const whendate = +new Date(when)
  const now = +new Date()
  const ago = (now - whendate) / 1000
  let msg = i18n("Now")

  if (ago < 10) {
    msg = i18n("Now")
  } else if (ago < 120) {
    msg = i18n("Just seconds ago")
  } else if (ago < 60 * 60) {
    msg = (ago / 60).toFixed() + " " + i18n("minutes ago")
  } else if (ago < 60 * 60 * 24) {
    msg = (ago / (60 * 60)).toFixed() + " " + i18n("hours ago")
  } else if (ago < 60 * 60 * 24 * 2) {
    msg = i18n("Yesterday")
  } else {
    msg = when.substring(0, 10)
  }

  return (
    <div title={datestr} className="timestamp">
      {msg}
    </div>
  )
}

function is_working_hours() {
  const date = new Date()
  const day = date.getDay()
  if (day === 0 || day === 6) {
    return false
  }
  const hour = date.getHours()

  for (const startend of settings.WORKING_HOURS) {
    if (hour >= startend[0] && hour < startend[1]) {
      return true
    }
  }

  return false
}
