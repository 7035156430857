import { Component, Fragment, h } from "preact"
import message, { MessageI } from "../utils/message"

interface MessageStackState {
  messages: MessageI[]
  max_id: number
}

class MessageStack extends Component<{}, MessageStackState> {
  state: MessageStackState = { messages: [], max_id: 1 }

  componentDidMount(): void {
    message.setAddMessage(this.addMessage.bind(this))
    message.setRemoveMessage(this.removeMessage.bind(this))
  }
  componentWillUnmount(): void {
    message.setAddMessage((msg: MessageI) => 0)
    message.setRemoveMessage((id: number) => 0)
  }

  addMessage(message: MessageI) {
    const id = this.state.max_id

    const messages = [...this.state.messages, { ...message, id }]
    this.setState({ messages, max_id: id + 1 })
    console.log("Add Message", message)
    return id
  }
  removeMessage(id: number) {
    const messages = this.state.messages.filter((x) => x.id !== id)
    this.setState({ messages })
    console.log("remove Message", id)
    return id
  }

  render() {
    if (this.state.messages.length === 0) {
      return <Fragment></Fragment>
    }
    return (
      <div className="absolute z-50 top-0 left-0 w-full flex flex-col items-center">
        {this.state.messages.map((msg) => (
          <Message
            message={msg}
            key={msg.id}
            onClose={() => this.removeMessage(msg.id)}
          />
        ))}
      </div>
    )
  }
}

const Message = ({
  message,
  onClose,
}: {
  message: MessageI
  onClose: () => void
}) => {
  let bgColor = "bg-white"
  switch (message.options.variant) {
    case "info":
      bgColor = "text-white bg-blue-500"
      break
    case "error":
      bgColor = "text-white bg-red-500"
      break
    case "warning":
      bgColor = "text-white bg-yellow-500"
      break
  }
  return (
    <div className={`rounded full p-6 w-1/2 mt-10 ${bgColor} flex flex-row`}>
      <div className="flex-1">{message.text}</div>
      <button
        onClick={onClose}
        className="rounded-full p-1  w-8 h-8 bg-transparent-200 hover:bg-transparent"
      >
        ✖
      </button>
    </div>
  )
}

export default MessageStack
