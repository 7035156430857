import { Component, h } from "preact"
import api from "../api"
import Loading from "../components/Loading"
import Error from "../components/Error"
import { replacePath, setPath } from "../router"
import { get_qs } from "../utils"
import dialog from "../components/Dialog"
import i18n from "../i18n"
import message from "../utils/message"
import CMSContent from "../components/CMSContent"
import { getUser } from "../utils/user"

class Service extends Component<{ service: string }, { error?: string }> {
  async componentDidMount() {
    const username = get_qs()["user"]
    let chatroom
    try {
      chatroom = await api.getOne(
        "chat/room",
        this.props.service,
        username
          ? {
              username,
            }
          : {}
      )
    } catch (e) {}
    if (!chatroom) {
      this.setState({ error: i18n("Need to accept contract") })
      const what = this.props.service
      const corporate = getUser().contract?.corporate?.username
      if (!corporate) {
        message.warning(
          i18n(
            "Current user is not an enduser with a contract, so it can not request new services."
          )
        )
        setPath("")
        return
      }

      await dialog({
        title: i18n("Service description"),
        children: (props) => (
          <CMSContent
            key="description"
            first_of={`filelist_description_${what}_chat|filelist_description_${what}`}
          />
        ),
        accept_label: i18n("Continue"),
      })

      await dialog({
        title: i18n("Service usage contract"),
        children: (props) => (
          <CMSContent
            key="agreement"
            first_of={`contract_agreement_${what}_${corporate}|contract_agreement_${what}|contract_agreement`}
          />
        ),
      })
      chatroom = await api.post("chat/room", {
        service: this.props.service,
        username,
      })
    }

    replacePath(`chat/${chatroom.id}`)
  }

  render() {
    if (this.state.error) {
      return <Error>{this.state.error}</Error>
    }
    return (
      <div className="flex items-center justify-center h-mainarea">
        <Loading />
      </div>
    )
  }
}

export default Service
