import i18n from "../i18n"
import { IdNameI } from "../types"
import utils from "../utils"
import message from "../utils/message"

export interface MessageLine {
  id: number
  author: string
  text: string
  timestamp: string
  attachments?: {
    name: string
    size: number
    url: string
  }[]
  file?: {
    id: number
    alternateId: string
    service: IdNameI
  }
}

interface RPCMessage {
  id?: number
  method?: string
  event?: string
  result?: any
  error?: {
    code: string
    message: string
  }
  payload?: any
}

interface SubscribeResult {
  messages: MessageLine[]
  name: string
  user: IdNameI
  service: IdNameI
}

interface ListenerI {
  id: number
  callback: (params: any) => void
  error?: (code: string, message: string) => void
}

export class ChatWS {
  ws?: WebSocket = undefined
  listeners: Record<string, ListenerI[]> = {}
  maxId: number = 1
  waitids: Record<number, (payload: any) => void> = {}
  token: string | undefined
  url: string | undefined
  status: "not-connected" | "connecting" | "connected" = "not-connected"
  pending: any[] = []
  onConnect: undefined | (() => void)
  onDisconnect: undefined | (() => void)

  get_chat_ws_url() {
    let url
    if (
      document.location.host === "localhost:8000" ||
      document.location.host === "localhost:1234"
    )
      return "ws://localhost:8001/"
    let scheme = "ws"
    if (document.location.protocol === "https:") {
      scheme = "wss"
    }
    url = `${scheme}://${document.location.host}/ws/`
    return url
  }

  connect(token: string = undefined, url: string = undefined) {
    console.log("Connecting to WS backend...", url)
    url = url || this.get_chat_ws_url()

    this.token = token
    this.url = url
    this.reconnect()
  }

  reconnect() {
    console.log("Connecting to ", this.url)
    if (this.status != "not-connected") {
      console.error("Reentry to reconnect, bad status", this.status)
    }
    this.ws = new WebSocket(this.url)
    this.ws.addEventListener("message", (event) => {
      this.receiveMessage(JSON.parse(event.data))
    })
    this.ws.addEventListener("close", () => {
      console.log("close")
      this.status = "not-connected"
      this.onDisconnect?.()
      this.wait_and_reconnect()
    })
    this.ws.addEventListener("error", async (ev) => {
      console.log("error")
      this.ws.close()
    })
    this.ws.addEventListener("open", async () => {
      console.log("open")
      this.status = "connected"
      this.sendPending()
      this.onConnect?.()
    })
    this.status = "connecting"
  }

  async wait_and_reconnect() {
    // console.log("Wait and reconnect")
    await utils.sleep(1000)
    this.reconnect()
  }

  async subscribe(room_id: string): Promise<SubscribeResult> {
    this.token = room_id
    const messages = await this.call<SubscribeResult>("subscribe", {
      token: this.token,
      lastid: 0,
    })

    return messages
  }

  async disconnect() {
    this.ws.close()
    this.ws = undefined
  }

  receiveMessage(msg: RPCMessage) {
    if (msg.result) {
      const id = msg.id
      if (this.waitids[id]) {
        this.waitids[id](msg.result)
        delete this.waitids[id]
      } else {
        console.error("Unknown message id", id)
      }
    }
    if (msg.event) {
      for (const listener of this.listeners[msg.event] || []) {
        listener.callback(msg.payload)
      }
    }
    if (msg.error) {
      let processed = false
      for (const listener of this.listeners[msg.event] || []) {
        if (listener.error) {
          listener.error(msg.error.code, msg.error.message)
          processed = true
        }
      }
      if (!processed) {
        message.error(msg.error.message)
      }
    }
  }

  async call<R = any>(method: string, params: any = {}) {
    const id = this.maxId
    this.maxId += 1
    const msg = JSON.stringify({
      id,
      method,
      params,
    })
    if (this.status == "connected") {
      this.ws.send(msg)
    } else {
      this.pending.push(msg)
    }
    return (await this.waitForMessageId(id)) as R
  }

  sendPending() {
    console.log("Send pending: %s, %o", this.status, this.pending)
    if (this.status != "connected") {
      return
    }
    const pending = this.pending
    this.pending = []
    for (const msg of pending) {
      console.log("Send pending %o", msg)
      this.ws.send(msg)
    }
  }

  async waitForMessageId(id: number) {
    return new Promise<any>((accept, reject) => {
      this.waitids[id] = accept
    })
  }

  addListener(event: string, callback: (params: any) => void) {
    const callbacks = this.listeners[event] || []
    this.listeners[event] = [
      ...callbacks,
      {
        id: this.maxId,
        callback,
      },
    ]
    this.maxId += 1
  }

  removeListener(event: string, listener_id: number) {
    this.listeners[event] = this.listeners[event]?.filter(
      (x) => x.id != listener_id
    )
  }

  onMessage(callback: (message: MessageLine) => void) {
    return this.addListener("message", (params: any) => callback(params))
  }

  async sendMessage(text: string) {
    this.call("post", {
      text,
      token: this.token,
    })
  }

  async sendAttachment(text: string, attachments: IdNameI[]) {
    this.call("post", {
      text,
      token: this.token,
      attachments,
    })
  }
  async seen(message_id: number) {
    this.call("seen", {
      token: this.token,
      message_id,
    })
  }
  async subscribe_room_activity(
    callback: (user_id: number, service_id: number, count: number) => void
  ) {
    this.addListener("room_activity", (params: any) =>
      callback(params.user_id, params.service_id, params.count)
    )
    this.call("subscribe_room_activity")
  }
  async unsubscribe_room_activity(listener: number) {
    this.removeListener("room_activity", listener)
    delete this.listeners["room_activity"]
    this.call("subscribe_room_activity", {
      token: this.token,
    })
  }
}
