import { Component, h } from "preact"
import i18n from "../i18n"

export interface DialogProps<T = any> {
  title?: string
  children?: (props: DialogChildrenProps<T>) => any
  value?: T
  accept_label?: string
  except_on_close?: boolean
  onAccept?: (value: T) => void
}

interface DialogChildrenProps<T> {
  value: T
  setValue: (update: Partial<T>) => void
}

export interface DialogStackProps<T = any> {
  dialog: DialogProps<T>
  accept: (value: any) => void
  reject: () => void
}

// Some component must overwrite this, and on each call add it a list or whatever and show it
export let showDialog: undefined | ((dsp: DialogStackProps | null) => void) =
  undefined

export function setShowDialog(newShowDialog: typeof showDialog) {
  const old = showDialog
  showDialog = newShowDialog
  return old
}

export function dialog(dialog: DialogProps) {
  const promise = new Promise<any>((accept, reject) => {
    if (showDialog)
      showDialog({
        dialog,
        accept,
        reject,
      })
    else reject("No dialog provider set up")
  })

  return promise
}

export class Dialog extends Component<DialogStackProps> {
  state: any

  constructor(props: DialogStackProps) {
    super(props)

    this.state = props.dialog.value
  }

  handleAccept() {
    this.props.dialog.onAccept?.(this.state)
    this.props.accept(this.state)
    showDialog(null)
  }
  handleClose() {
    if (this.props.dialog.except_on_close) {
      this.props.reject()
    }
    showDialog(null)
  }

  render() {
    const { props, state } = this
    return (
      <div className="bg-white shadow-lg rounded-xl hm-dialog">
        <div className="flex flex-row bg-gray-400 items-center relative rounded-t-xl">
          <div className="p-6 font-bold text-3xl text-white flex-1">
            {this.props.dialog.title}
          </div>
          <button
            className="m-6 bg-button"
            onClick={this.handleAccept.bind(this)}
          >
            {this.props.dialog.accept_label || i18n("Accept")}
          </button>
          <button
            className="close-dialog"
            onClick={this.handleClose.bind(this)}
          >
            ✖
          </button>
        </div>
        <div className="p-4 overflow-auto hm-dialog-content">
          {props.dialog.children({
            value: state,
            setValue: this.setState.bind(this),
          })}
        </div>
      </div>
    )
  }
}

export default dialog
