import { Component, Fragment, h } from "preact"
import { User } from "."
import api from "./api"
import i18n from "./i18n"
import { setPath } from "./router"
import { UserI } from "./utils/user"

interface HeaderProps {
  onLogout: () => void
}
interface HeaderState {
  promptInstallEvent?: any
  menu_open: boolean
}

class Header extends Component<HeaderProps, HeaderState> {
  state: HeaderState = { menu_open: false }
  componentDidMount(): void {
    window.addEventListener("beforeinstallprompt", (promptInstallEvent) => {
      this.setState({ promptInstallEvent: promptInstallEvent })
    })
  }

  async handleInstallPWA() {
    this.state.promptInstallEvent.prompt()
    this.setState({ promptInstallEvent: undefined })
  }

  render() {
    return (
      <User.Consumer>
        {(user) => (
          <Fragment>
            <div
              className="flex flex-row bg-white py-2 items-center header relative z-40 shadow"
              style={{ boxShadow: "0px 0 5px 1px rgba(0,0,0,0.5)", height: 85 }}
            >
              <button
                onClick={() =>
                  this.setState({ menu_open: !this.state.menu_open })
                }
                className="ml-2 px-4 flex flex-row items-center"
              >
                {this.state.menu_open ? (
                  <img
                    src={require("../static/img/icons/hamburger_close.png")}
                  />
                ) : (
                  <img src={require("../static/img/icons/hamburger.png")} />
                )}
                <span className="p-4 text-xs">{i18n("Menu")}</span>
              </button>
              <div className="flex-1 justify-center flex">
                <button onClick={() => setPath("/")} className="hidden sm:flex">
                  <img src={user.brand.logo_img} style={{ maxHeight: 65 }} />
                </button>
              </div>
              {this.state.promptInstallEvent && (
                <button
                  className="bg-blue-400 text-white p-4 shadow-lg m-2 rounded mr-8"
                  onClick={() => {
                    this.handleInstallPWA()
                  }}
                >
                  {i18n("Install HealthMotiv App")}
                </button>
              )}

              <a className="flex flex-row" href={`/app/`}>
                <div className="flex-col text-sm text-left mr-2 hidden sm:flex">
                  <div>
                    {user.first_name} {user.last_name}
                  </div>
                  <div>{user.email}</div>
                </div>
                <img
                  className="mx-6"
                  src={require("../static/img/icons/home.svg")}
                  style={{ width: 32 }}
                />
              </a>
              <button
                className="mx-6"
                onClick={async () => {
                  await api.post("user/logout")
                  this.props.onLogout()
                }}
              >
                {/* {i18n("Logout")} */}
                <img
                  src={require("../static/img/icons/logout.svg")}
                  style={{ width: 32 }}
                />
              </button>
            </div>
            {this.state.menu_open && (
              <SideMenu
                user={user}
                onClose={() =>
                  this.setState({ menu_open: !this.state.menu_open })
                }
              />
            )}
          </Fragment>
        )}
      </User.Consumer>
    )
  }
}

const SideMenu = (props: { user: UserI; onClose: () => void }) => (
  <div
    className="absolute left-0 bg-color2 text-white border-black z-30 flex flex-col overflow-auto"
    style={{
      minHeight: "calc(100vh - 84px)",
      maxHeight: "calc(100vh - 84px)",
      top: 84,
      boxShadow: "3px 0 5px -1px rgba(0,0,0,0.5)",
      width: 320,
    }}
  >
    {props.user.brand.navmenu.map((item) => (
      <SideMenuButton
        key={item.url}
        label={i18n(item.label)}
        onClick={() => {
          window.location = item.url
          props.onClose()
        }}
      />
    ))}
    <div className="flex-1"></div>
  </div>
)

const SideMenuButton = (props: { label: string; onClick: () => void }) => (
  <button
    onClick={props.onClick}
    className="mt-6 hover:bg-white hover:text-color2 w-full flex align-center justify-start"
  >
    <div className="border-b ml-6 w-full text-left py-2 bt-2 text-18px">
      {props.label}
    </div>
  </button>
)

export default Header
