import { Component, createContext, Fragment, h, render } from "preact"
import "preact/debug"
import api from "./api"
import { DialogStackProps, setShowDialog, Dialog } from "./components/Dialog"
import Login from "./components/Login"
import MessageStack from "./components/MessageStack"
import Header from "./Header"
import i18n, { setTranslations } from "./i18n"
import { getComponent, setPath } from "./router"
import { get_qs } from "./utils"
import "./serviceworker"
import { setUser, UserI } from "./utils/user"

// export const Route = createContext({ path: "/", setPath(url: string): void })
export const User = createContext({
  username: "",
  first_name: "",
  last_name: "",
  email: "",
  brand: {
    shortname: "healthmotiv",
    index_url: "/",
    logo_img: "/static/img/logo.png",
  },
  id: 0,
} as UserI)

interface AppState {
  path: string
  user?: UserI
  popup?: DialogStackProps
  embeded?: boolean
  loading: boolean
}

class App extends Component {
  state: AppState = { path: "/", loading: true }

  async componentDidMount() {
    window.addEventListener("pushstate", () =>
      this.setState({ path: window.location.pathname })
    )
    window.addEventListener("popstate", () =>
      this.setState({ path: window.location.pathname })
    )

    let user: UserI
    try {
      user = await api.getOne("user", "me")
    } catch (e) {
      this.setState({ loading: false })
      // Need log in
      return
    }
    setUser(user)
    this.setState({ user, loading: false })

    if (user.lang === "es") {
      setTranslations(await import("../static/i18n/es.json"))
    }
    this.setColorPalette(user)

    setPath(window.location.pathname.slice(5))

    setShowDialog(this.showDialog.bind(this))
    console.log(get_qs())
    const embeded = !!("embeded" in get_qs())
    this.setState({ embeded })

    this.loadManifest(user.brand.shortname)
  }

  loadManifest(brand?: string) {
    if (!brand) brand = this.state.user.brand.shortname
    document
      .querySelector("#manifest")
      .setAttribute("href", `/api/v1/pwa/manifest/${brand}.webmanifest`)
  }

  setColorPalette(user: UserI) {
    const style = document.createElement("style")
    const brand = user.brand
    style.innerText = `body{ 
      --color1: ${brand.color1}; 
      --color2: ${brand.color2};
      --color3: ${brand.color3};
      --color4: ${brand.color4};
    }`
    document.getElementsByTagName("body")[0].appendChild(style)
  }

  async showDialog(dsp: DialogStackProps | null) {
    console.log("Show Dialog", dsp)
    this.setState({ popup: dsp })
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="flex flex-col items-center justify-center h-screen w-full bg-white text-black">
          <span className="m-4">{i18n("Loading...")}</span>
          <img alt="" src={require("../static/img/loading.gif")} />
        </div>
      )
    }

    const { path, embeded, user, popup } = this.state
    if (!user) {
      return <Login onLogin={() => this.componentDidMount()} />
    }

    return (
      <User.Provider value={user}>
        <MessageStack />
        <div className="flex flex-col h-screen min-h-screen max-h-screen overflow-hidden">
          {!embeded && (
            <Header
              onLogout={() => {
                this.setState({ user: undefined })
                setUser(undefined)
                setPath(user.brand.index_url)
              }}
            />
          )}
          <div
            className={`h-full ${
              embeded ? "pt-0" : "max-h-mainarea"
            } flex flex-col overflow-auto flex-1`}
            key={path}
          >
            {getComponent(path)}
          </div>
          {popup && (
            <div
              className="fixed top-0 left-0 flex items-center justify-center w-screen h-screen z-30"
              style={{ background: "rgba(191,224,104,0.6)" }}
              id="bgpopup"
              onClick={(ev) => {
                if (ev.target.id == "bgpopup") this.setState({ popup: null })
              }}
            >
              <Dialog {...popup} />
            </div>
          )}
        </div>
      </User.Provider>
    )
  }
}

// function get_data(key: string) {
//   return (document.querySelector("#root") as any).dataset[key]
// }

render(<App />, document.getElementById("root"))

function setup_debugging() {
  const console = window.console
  console.log("START DEBUGGING")

  const logger = document.createElement("div")
  logger.className = "scroll bg-white text-bnlack"
  logger.style.minHeight = "100px"

  function writeLog(className: string, text: string) {
    const pre = document.createElement("pre")
    pre.innerText = text
    pre.className = className
    logger.appendChild(pre)
  }

  document.body.appendChild(logger)

  window.console = {
    ...console,
    info(...data) {
      console.info(...data)
      writeLog("", data.join(" "))
    },
    error(...data) {
      console.info(...data)
      writeLog("text-red", data.join(" "))
    },
    warn(...data) {
      console.info(...data)
      writeLog("text-orange", data.join(" "))
    },
    debug(...data) {
      console.info(...data)
      writeLog("text-blue", data.join(" "))
    },
    log(...data) {
      console.info(...data)
      writeLog("", data.join(" "))
    },
  }

  window.addEventListener("unhandledrejection", (event) => {
    writeLog("text-red", `Exception ${event} ${event.reason}`)
  })
  window.console.info("Ready")
}

// setup_debugging()
