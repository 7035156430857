import { Component, createRef, h } from "preact"
import { parse } from "marked"

class Markdown extends Component<{ text: string; className: string }> {
  div_ref = createRef()
  componentDidMount(): void {
    const html = parse(this.props.text)
    this.div_ref.current.innerHTML = html
  }
  render() {
    return <div className={this.props.className} ref={this.div_ref}></div>
  }
}

export default Markdown
