export interface ResultI<T = any> {
  results: T[]
  count: number
}

export class API {
  base_url: string = `${window.location.protocol}//${window.location.host}/api/v1`

  async get<T = any>(collection: string, qs?: any) {
    let url = `${this.base_url}/${collection}/`
    if (qs) {
      const urlobj = new URL(url)
      urlobj.search = new URLSearchParams(qs).toString()
      url = urlobj.toString()
    }

    const request = new Request(url, {
      method: "GET",
    })

    const res = await fetch(request, {})

    if (res.ok) {
      const data = await res.blob()
      const text = await data.text()
      return JSON.parse(text) as T
    }
    throw new APIError(res)
  }

  async getOne<T = any>(collection: string, pk: number | string, qs?: any) {
    return this.get(`${collection}/${pk}`, qs)
  }

  async post<T = any>(collection: string, data?: any) {
    let url: string
    let body: string | FormData
    let headers: Record<string, string> = {}
    if (collection.startsWith("/")) {
      url = collection
    } else {
      url = `${this.base_url}/${collection}/`
    }

    // If has data is a FormData, pass as is
    if (data && data.append) {
      body = data
      // headers["content-type"] =
      //   "application/x-www-form-urlencoded; charset=UTF-8"
    } else {
      body = JSON.stringify(data || {})
      headers["content-type"] = "application/json"
    }

    const res = await fetch(url, {
      method: "POST",
      body,
      headers: {
        ...headers,
        "x-csrftoken": get_cookie("csrftoken"),
      },
      mode: "same-origin",
    })

    if (res.ok) {
      const data = await res.blob()
      const text = await data.text()
      return JSON.parse(text) as T
    }
    throw new APIError(res)
  }

  async patch<T = any>(collection: string, data?: any) {
    let url = `${this.base_url}/${collection}/`

    const request = new Request(url, {
      method: "PATCH",
      body: JSON.stringify(data),
    })

    const res = await fetch(request, {
      headers: {
        "content-type": "application/json",
        "x-csrftoken": get_cookie("csrftoken"),
      },
      mode: "same-origin",
    })

    if (res.ok) {
      const data = await res.blob()
      const text = await data.text()
      return JSON.parse(text) as T
    }
    throw new APIError(res)
  }
}

function get_cookie(name: string) {
  let cookieValue = null
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";")
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
        break
      }
    }
  }
  return cookieValue
}

class APIError {
  response: Response
  _json?: any

  constructor(response: Response) {
    this.response = response
  }
  async json() {
    if (this._json === undefined) {
      const data = await this.response.blob()
      const text = await data.text()
      this._json = JSON.parse(text)
    }
    return this._json
  }
}

const api = new API()

export default api
