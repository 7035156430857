import { h } from "preact"
import i18n from "../i18n"

const Loading = (props: { className?: string }) => (
  <div className={props.className}>
    {i18n("Loading...")}
    <img
      className="pl-4"
      alt=""
      src={require("../../static/img/loading.gif")}
    />
  </div>
)

export default Loading
